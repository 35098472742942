const firebaseConfig = {
	apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0', 
	authDomain: 'web-a2bf7.firebaseapp.com', 
	projectId: 'web-a2bf7',
	storageBucket: 'web-a2bf7.appspot.com', 
	messagingSenderId: '780267482533', 
	appId: "1:780267482533:web:0e956276c90c0e57bf0c4c",
	measurementId: 'G-XS305K2H2X',
}

export default firebaseConfig
